<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float:left">
        <el-button v-if="auth['customer:add']" type="primary" icon="el-icon-plus" @click="handle_add()">添加</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
      </div>
      <div style="float:right;">
        <el-input placeholder="客户名称" v-model="customerName" class="input-with-select" style="width:350px;"></el-input>&nbsp;
        <el-button type="primary" @click="handle_search()" icon="el-icon-search">搜索</el-button>
      </div>
    </div>
    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="name" label="客户名称" width="350"></el-table-column>
<!--      <el-table-column prop="contact" label="联系人" width="100"></el-table-column>-->
<!--      <el-table-column prop="phone" label="联系电话" width="100"></el-table-column>-->
      <!--      <el-table-column prop="type" label="类型" width="50"></el-table-column>-->
      <el-table-column prop="tag" label="标签" width="400">
        <template  #default="scope">
          <el-tag style="margin-left: 5px;"
                  v-for="(tag, index) in customerTagInfo[scope.row.id]"
                  :key="tag.name">
            {{tag.name}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" width="100">
        <template  #default="scope">{{customerType[scope.row.type]}}</template>
      </el-table-column>
      <el-table-column prop="level" label="级别" width="100">
        <template  #default="scope">{{customerLevel[scope.row.level]}}</template>
      </el-table-column>
<!--      <el-table-column prop="province" label="省份" width="100"></el-table-column>-->
<!--      <el-table-column prop="city" label="城市" width="100"></el-table-column>-->
<!--      <el-table-column prop="area" label="区域" width="100"></el-table-column>-->
<!--      <el-table-column prop="status" label="状态" width="50"></el-table-column>-->
      <el-table-column prop="scope" label="操作" min-width="100">
        <template  #default="scope">
          <el-button v-if="auth['customer:detail']" type="text" icon="el-icon-view" @click="handle_view(scope.row)">客户详情</el-button>
<!--          <el-button type="text" icon="el-icon-edit" @click="handle_follow(scope.row)">跟进</el-button>-->
<!--          <el-button type="text" icon="el-icon-edit" @click="handle_createorder(scope.row)">新建订单</el-button>-->
<!--          <el-button type="text" icon="el-icon-edit" @click="handle_createproject(scope.row)">新建项目</el-button>-->
          <el-button v-if="auth['customer:del']" type="text" icon="el-icon-delete" class="red" @click="handle_del(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>

  </div>
</template>

<script>
import CUS from '../../api/biz/api_customer';
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";
import ORDER from "@/api/biz/api_order";
import PM from "@/api/base/api_pm";

export default {
  name: "customerlist",
  components: {
    // formpage
  },
  props: {
    customerName: {
      type: String
    }
  },
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      editTitle: '添加客户信息',
      editShow: false,
      editCustomer: {editTitle: "添加客户信息"},
      customerTagList: [],
      customerTagInfo: {},
      customerLevel: {
        0: 'A', 1: 'B', 2: 'C', 3: 'D'
      },
      customerType: {
        0: '潜在', 1: '合作', 2: '流失'
      },
      auth: {},
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },

  // 方法定义
  methods: {
    init() {
      this.init_base();
      this.auth=this.$getAuth('/customer');
      this.initPageData();
    },

    // 加载初始数据支持
    init_base(){
      COMM.getObjType({type: 'customertag'}).then((res) => {
        this.customerTagList = res;
      });
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      // 执行查询
      CUS.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        keyword: this.customerName
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;

          // 构造客户标签
          this.buildCustomerTags(data.list);
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },

    // 翻页
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    buildCustomerTags(customerList) {
      if (customerList == null || customerList.length == 0) {
        return;
      }

      for (const index in customerList) {
        let customer = customerList[index];
        let customerId = customer['id'];

        let customerCheckedTags = [];
        let tag = customer.tag||'';
        for (const index in this.customerTagList) {
          let customerTag = this.customerTagList[index];
          if (tag.indexOf(customerTag['value'] + ',') >= 0) {
            customerCheckedTags.push(customerTag);
          }
        }

        this.customerTagInfo[customerId] = customerCheckedTags;
      }
    },

    // 查询
    handle_search() {
      this.initPageData();
    },

    // 新增
    handle_add() {
      // this.editTitle = "添加客户信息";
      // this.editCustomer = {};
      // this.editShow = true;
      let customerId = null;
      this.$router.push({path: '/customerEdit/' + customerId});
    },

    // 查看
    handle_view(row) {
      let {...clonerow} = row;
      //this.$router.push({ name: 'customerViewPage', params: { customerid: row.id } });
      this.$router.push({path: '/customerViewPage/' + row.id});
    },
    //follow
    handle_follow(row) {
      let {...clonerow} = row;
      this.$router.push({ path: 'followEdit', query: { customerId: row.id } });
    },
    //create order
    handle_createorder(row) {
      // let {...clonerow} = row;
      this.$router.push({ path: 'createOrder', query: { customerId: row.id } });
    },
    //create project
    handle_createproject(row) {
      // let {...clonerow} = row;
      this.$router.push({ path: 'projectEdit', query: { customerId: row.id } });
    },
    // 删除
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.name + '] 客户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        CUS.delete({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    }

  }
}
</script>

<style scoped>
  .pageHeader {
    background-color: white;
    border-bottom: 1px solid #CCCCCC;
    font-size: 18px;
    padding: 5px 10px;
  }

  .red {
    color: #ff0000;
  }
</style>